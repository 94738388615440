<template>
  <div v-cloak>
    <div v-if="apiLoaded || errorText">
      <div v-if="apiLoaded" class="wrap">
        <a @click="$router.go(-1)" class="back-link">
          <v-icon color="#0033A0">mdi-chevron-left</v-icon>
          Назад
        </a>
        <h1>Настройка подсистемы «Конструктор комплексной модернизации»</h1>

        <table class="config-list-table">
          <thead>
            <tr>
              <th>Id конфигурации</th>
              <th>Наименование</th>
              <th>Дата создания</th>
              <th>Дата изменения</th>
              <th>Статус</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="config in configs" :key="config.id">
              <td>{{ config.id }}</td>
              <td>{{ config.title }}</td>
              <td>{{ config.createdDate | dateFormat }}</td>
              <td v-if="config.modifiedDate">{{ config.modifiedDate | dateFormat }}</td>
              <td v-else>–</td>
              <td v-if="config.active">
                <div style="color: darkgreen;">Активный</div>
                <a href="#" @click.stop="changeActivity('toInactive', config.id)">Сделать неактивным</a>
              </td>
              <td v-else>
                <div>Неактивный</div>
                <a href="#" @click.stop="changeActivity('toActive', config.id)">Сделать активным</a>
              </td>
              <td style="width: 10px">
                <div v-if="config.activateCounter !== 0 && config.activateCounter !== null"
                     class="edit" title="Просмотр данных комплексной модернизации по данной конфигурации">
                  <router-link :to="'/standard/vnii/'+config.id">
                    <v-icon color="#0033A0">mdi-briefcase-eye-outline</v-icon>
                  </router-link>
                </div>
                <div v-else class="edit" title="Просмотр данных невозможен, т.к. конфигурация не была активирована">
                  <v-icon color="#777779">mdi-briefcase-eye-outline</v-icon>
                </div>
              </td>
              <td style="width: 10px">
                <div v-if="config.activateCounter === 0 || config.activateCounter === null"
                     class="edit"
                     title="Редактирование конфигурации">
                  <router-link :to="'/config/standardMonitoringEdit/'+config.id">
                    <v-icon color="#0033A0">mdi-note-edit-outline</v-icon>
                  </router-link>
                </div>
                <div v-else class="edit" title="Редактирование ранее активированных конфигураций запрещено">
                  <v-icon color="#777779">mdi-note-edit-outline</v-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="save-buttons">
          <v-btn color="primary" @click="$router.push('/config/standardMonitoringEdit/')">Создать конфигурацию</v-btn>
        </div>

      </div>
      <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
        Ошибка: {{ errorText }}
      </div>
      <div v-else style="height:1000px"></div>
    </div>
  </div>
</template>

<script>
import api from "@/modules/api";

export default {
  name: "standardMonitoring",
  props: {
  },
  components: {},
  data() {
    return {
      configs: [],
      apiLoaded: false,
      errorText: null,
      apiFreeze: false //уже ушел запрос апи - нужно дождаться его окончания, прежде чем слать новые запросы
    };
  },

  methods: {
    async changeActivity(status, configId) {
      if (this.apiFreeze) {
        return
      }
      this.apiFreeze = true;
      let req,
          obj = {configId: Number(configId)};
      if (status === 'toActive') {
        obj.isActive = true;
      } else if (status === 'toInactive') {
        obj.isActive = false;
      }
      req = await api.postJson("/monitoring/typical/config/changeActivity", obj);
      if (req.ok) {
        await this.getData()
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.apiFreeze = false
    },

    async getData() {
      let req = await this.$getApi("/monitoring/typical/config/getConfigList")
      if (req.ok) {
        this.configs = req.payload
        // console.log('this.configs', this.configs)
        this.sortConfigsById()
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    sortConfigsById() {
      //больший id - в начало
      this.configs.sort((a, b) => (a.id < b.id) ? 1 : -1)
    }
  },

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  async beforeMount() {
    await this.getData()
  }
};
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style scoped lang="scss">
.config-list-table {
  border-collapse: collapse;
  a {
    font-size: 14px;
  }
}
</style>